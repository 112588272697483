import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css'

import { Button, Input, DatePicker, Select, Option, CheckboxGroup, Checkbox, Carousel, CarouselItem } from 'view-design'

import 'view-design/dist/styles/iview.css'

Vue.component('Button', Button)
Vue.component('Input', Input)
Vue.component('CheckboxGroup', CheckboxGroup)
Vue.component('Checkbox', Checkbox)
Vue.component('Select', Select)
Vue.component('Option', Option)
Vue.component('Carousel', Carousel)
Vue.component('DatePicker', DatePicker)
Vue.component('CarouselItem', CarouselItem)

Vue.config.productionTip = false
Vue.prototype.$layer = layer(Vue)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
