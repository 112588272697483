import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: sessionStorage.getItem('state') ? JSON.parse(sessionStorage.getItem('state')) : {
    token: '',
    name: ''
  },
  mutations: {
    login (state, userinfo) {
      state.token = userinfo.access_token
      state.name = userinfo.name
      sessionStorage.setItem('state', JSON.stringify(state))
    },
    login_out (state) {
      state.token = ''
      state.account = ''
      sessionStorage.setItem('state', JSON.stringify(state))
    }
  },
  actions: {},
  modules: {}
})
